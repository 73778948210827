.popin{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
    justify-content: center;
    align-items: center;
    display: none;

    &--open{
        display: flex;
    }

    .popin__content{
        background-color: #fff;
        padding: 20px;
        padding-bottom: 50px;
        width: 80%;
        max-width: 600px;
        position: relative;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        min-height: 200px;

        .btn-close
        {
            cursor: pointer;
            align-self: flex-end;
        }
    }
}
