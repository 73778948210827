// @font-face {
//     font-family: 'ITCavantGarde';
//     src: url('./fonts/ITCAvantGardeStdBk.woff') format('woff');
//     font-weight: 500;
//     font-style: normal;
//   }

// @font-face {
//   font-family: 'ITCavantGarde';
//   src: url('./fonts/ITCAvantGardeStdBold.woff') format('woff');
//   font-weight: 800;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'ITCavantGarde';
//   src: url('./fonts/ITCAvantGardeStdXLt.woff') format('woff');
//   font-weight: 300;
//   font-style: normal;
// }

@font-face {
  font-family: "ITCavantGarde";
  src: url("./fonts/ITCAvantGardePro-Bk.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "ITCavantGarde";
  src: url("./fonts/ITCAvantGardePro-Md.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "ITCavantGarde";
  src: url("./fonts/ITCAvantGardePro-Bold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Baikal-light";
  src: url("./fonts/Baikal-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Baikal";
  src: url("./fonts/Baikal-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}