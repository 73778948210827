.popin-texte{
    font-size: 30px;
    font-family: "Baikal-light";
    max-width: 500px;

    .red{
        color: #E60000;
    }
}

.popin-link{
    font-size: 18px;
    text-decoration: none;
    color: black;
    position: relative;
    font-family: "Baikal";

    &::after{
        content: "";
        position: absolute;
        width: 110%;
        height: 2px;
        bottom: -2px;
        left: -5%;
        background-color: black;
    }
}